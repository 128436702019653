<template>
  <div id="app">
    <router-view/>
    <Footer v-if="showFooter" />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  components: {Footer},
  props: {},
  data() {
    return {

    };
  },
  computed: {
    showFooter () {
      let name = this.$route.name 
      if(name == 'Card' || name == 'Account' || name == 'Coin' || name == 'User'){
        return true
      }else{
        return false
      }
    }
  },
  watch: {},
  created() { },
  mounted() { },
  methods: {
    
  },
}
</script>
<style lang="less">

</style>
