// 引入文件
import request from '@/plugin/request'

export const login = (data) => {
    return request({
        url: '/login',
        method: 'post',
        data
    })
}

export const getWithdraw = () => {
    return request({
        url: '/captcha',
        method: 'get'
    })
}

export const loginout = (data) => {
    return request({
        url: '/api/logout',
        method: 'post',
        data
    })
}

export const sendCode = (data) => {
    return request({
        url: '/api/sendCode',
        method: 'post',
        data
    })
}

export const register = (data) => {
    return request({
        url: '/reg',
        method: 'post',
        data
    })
}

export const updatePwd = (data) => {
    return request({
        url: '/api/updatePwd',
        method: 'post',
        data
    })
}

export const resetPwd = (data) => {
    return request({
        url: '/api/resetPwd',
        method: 'post',
        data
    })
}

// 会员详情
export const getUserInfo = (data) => {
    return request({
        url: '/api/getUserInfo',
        method: 'post',
        data
    })
}

export const updateUserInfo = (data) => {
    return request({
        url: '/api/updateUserInfo',
        method: 'post',
        data
    })
}


