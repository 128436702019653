import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
    //修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '*',
    name: 'Login',
    component: Login
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/user',
    name: 'User',
    component: () => import('../views/user/index.vue'),
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/account/index.vue')
  },
  {
    path: '/coin',
    name: 'Coin',
    component: () => import('../views/coin/index.vue')
  },
  {
    path: '/card',
    name: 'Card',
    component: () => import('../views/card/index.vue')
  },
  {
    path: '/openCard',
    name: 'OpenCard',
    component: () => import('../views/card/openCard/index.vue')
  },
  {
    path: '/code',
    name: 'Code',
    component: () => import('../views/user/myCode/index.vue'),
  },
  {
    path: '/mode',
    name: 'Mode',
    component: () => import('../views/user/mode/index.vue'),
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import('../views/user/service/index.vue'),
  },
  {
    path: '/category',
    name: 'Category',
    component: () => import('../views/user/category/index.vue'),
  },
  {
    path: '/security',
    name: 'Security',
    component: () => import('../views/user/security/index.vue'),
  },
  {
    path: '/resetPwd',
    name: 'ResetPwd',
    component: () => import('../views/user/resetPwd/index.vue'),
  },
  {
    path: '/forgetPwd',
    name: 'ForgetPwd',
    component: () => import('../views/user/forgetPwd/index.vue'),
  },
  {
    path: '/createdCard',
    name: 'CreatedCard',
    component: () => import('../views/card/createdCard/index.vue'),
  },
  {
    path: '/recharge',
    name: 'Recharge',
    component: () => import('../views/account/recharge/index.vue'),
  },
  {
    path: '/transfer',
    name: 'Transfer',
    component: () => import('../views/account/transfer/index.vue'),
  },
  {
    path: '/commission',
    name: 'Commission',
    component: () => import('../views/user/commission/index.vue'),
  },
  {
    path: '/coinRecharge',
    name: 'CoinRecharge',
    component: () => import('../views/coin/coinRecharge/index.vue'),
  },
  {
    path: '/recordList',
    name: 'RecordList',
    component: () => import('../views/account/recordList/index.vue'),
  },
  {
    path: '/agentList',
    name: 'AgentList',
    component: () => import('../views/user/agentList/index.vue'),
  },
  {
    path: '/rebate',
    name: 'rebate',
    component: () => import('../views/user/rebate/index.vue'),
  },
  {
    path: '/teamList',
    name: 'TeamList',
    component: () => import('../views/user/teamList/index.vue'),
  },
  {
    path: '/notice',
    name: 'Notice',
    component: () => import('../views/user/notice/index.vue'),
  },
  {
    path: '/changeList',
    name: 'ChangeList',
    component: () => import('../views/account/changeList/index.vue'),
  },
]

const router = new VueRouter({
  routes
})

export default router
