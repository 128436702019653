
export const toFormData = (data) => {
  if (data === null) return null
  return Object.keys(data).reduce((formData, item) => {
    if (item === 'files') {
      data[item] &&
        data[item].forEach((curr) => {
          formData.append('upload_file[]', curr.originFileObj)
        })
    } else {
      formData.append(item, data[item])
    }
    return formData
  }, new FormData())
}