<template>
  <div data-v-4f16f5fa="" class="main1 pc_r">
    <app-header title="注册" pathName="Login"></app-header>
    <img data-v-4f16f5fa="" src="@/assets/icons/download (2).png"
      class="logon_r_bg">
    <p data-v-4f16f5fa="" class="title">
      创建Benforpay账户
    </p>
    <div data-v-4f16f5fa="" class="info">
      <div data-v-4f16f5fa="" class="info_b choose_box">
        <div data-v-4f16f5fa="" class="choose">
          <div data-v-4f16f5fa="" class="acitve">邮箱注册</div>
        </div>
      </div>
    </div>
    <div data-v-4f16f5fa="" class="info">
      <p data-v-4f16f5fa="" class="info_t">邮箱</p>
      <div data-v-4f16f5fa="" class="info_b">
        <input data-v-4f16f5fa="" v-model="fromData.email" type="text" placeholder="请输入邮箱地址">
      </div>
    </div>
    <div data-v-4f16f5fa="" class="info">
      <p data-v-4f16f5fa="" class="info_t">登录密码</p>
      <div data-v-4f16f5fa="" class="info_b">
        <input data-v-4f16f5fa="" placeholder="登录密码" v-model="fromData.password" :type="showPwd ? 'text' : 'password'">
        <img data-v-4f16f5fa="" src="@/assets/icons/download (5).png" class="show" v-if="showPwd" @click="changeShowPwd">
        <img data-v-4f16f5fa="" src="@/assets/icons/download (8).png" class="show" v-else  @click="changeShowPwd">
      </div>
      <span style="color: red">* 密码只能由数字和字母组成</span>
    </div>
    <div data-v-4f16f5fa="" class="info">
      <p data-v-4f16f5fa="" class="info_t">重复密码</p>
      <div data-v-4f16f5fa="" class="info_b">
        <input data-v-4f16f5fa="" placeholder="重复登录密码" v-model="fromData.sure_pwd" :type="showSurePwd ? 'text' : 'password'">
        <img data-v-4f16f5fa="" src="@/assets/icons/download (5).png" class="show" v-if="showSurePwd"  @click="changeShowSurePwd">
        <img data-v-4f16f5fa="" src="@/assets/icons/download (8).png" class="show" v-else  @click="changeShowSurePwd">
      </div>
    </div>
    <div data-v-4f16f5fa="" class="info">
      <p data-v-4f16f5fa="" class="info_t">用户名(选填)</p>
      <div data-v-4f16f5fa="" class="info_b">
        <input data-v-4f16f5fa="" type="text" v-model="fromData.account" placeholder="请输入用户名">
      </div>
    </div>
    <div data-v-4f16f5fa="" class="info">
      <p data-v-4f16f5fa="" class="info_t">手机号(选填)</p>
      <div data-v-4f16f5fa="" class="info_b">
        <input data-v-4f16f5fa="" type="text" placeholder="请输入手机号" v-model="fromData.mobile">
      </div>
    </div>
    <div data-v-4f16f5fa="" class="info">
      <p data-v-4f16f5fa="" class="info_t">推荐码(选填)
        </p>
      <div data-v-4f16f5fa="" class="info_b">
        <input data-v-4f16f5fa="" :disabled="disabled" type="text" placeholder="请输入推荐码"  v-model="fromData.invite_code">
      </div>
    </div> 
    <div data-v-4f16f5fa="" class="info">
      <p data-v-4f16f5fa="" class="info_t">邮箱验证码
        </p>
      <div data-v-4f16f5fa="" class="info_b" style="display: flex;">
        <input data-v-4f16f5fa="" type="text" placeholder="请输入邮箱验证码"  v-model="fromData.verify_code">
        <button id="sendCodeId" data-v-4f16f5fa="" style="    color: #fff;
          background: #2e7cff;margin-left: 10px;width: 120px;" :disabled="!fromData.email" 
          @click="sendCodeFn">发送验证码</button>
      </div>
      <span style="color: red">* 若未收到验证码，请在邮箱查看是否被识别为垃圾邮件</span>
    </div>

    <button data-v-4f16f5fa="" class="button0" @click="submitReg">注册</button>
  </div>
</template>

<script>
// @ is an alias to /src
import { register,sendCode } from "@/api"
import { mapActions } from 'vuex'
import { Toast } from 'vant';
export default {
  name: 'my-reg',
  components: {},
  props: {},
  data() {
    return {
      fromData: {
        password: '',
        sure_pwd: '',
        email: '',
        verify_code: '',
        mobile: '',
        account: '',
        invite_code: '',
      },
      InterValObj:null,//timer变量，控制时间
      count : 60, //当前剩余秒数
      curCount:'',

      showPwd: false,
      showSurePwd: false,

      sendCodeState: false,
      disabled: false
    };
  },
  computed: {},
  watch: {},
  created() { 
    if(this.$route.query.invite_code) {
      this.disabled = true
      this.fromData.invite_code = this.$route.query.invite_code
    }
    
  },
  mounted() { },
  beforeDestroy() {
    window.clearInterval(this.InterValObj);
  },
  methods: {
    changeShowPwd(){
      this.showPwd = !this.showPwd
    },
    changeShowSurePwd(){
      this.showSurePwd = !this.showSurePwd
    },
    ...mapActions(['setUser']),
    sendCodeFn() {
      this.curCount = this.count;
      document.getElementById("sendCodeId").setAttribute(
              'disabled',"true"
      );
      document.getElementById("sendCodeId").innerText = this.curCount+"秒后获取";
      this.InterValObj = window.setInterval( this.SetRemainTime, 1000); // 启动计时器timer处理函数，1秒执行一次

      sendCode({
        type: 0,
        account: this.fromData.email
      })
      Toast.success('验证码发送成功')
    },
    async submitReg() {
      const { password, sure_pwd, email , verify_code } = this.fromData
      if (!email.trim()) {
        return Toast('请输入电子邮箱');
      }
      if (!password.trim()) {
        return Toast('请输入密码');
      }
      if (!sure_pwd.trim()) {
        return Toast('请输入重复密码');
      }
      if (password.trim() !== sure_pwd.trim()) {
        return Toast('重复密码输入不一致');
      }
      if (!verify_code.trim()) {
        return Toast('请输入邮箱验证码');
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: false
      });
      const { code, message } = await register({
        ...this.fromData
      }).finally(() => {
        Toast.clear();
      })
      if (code == 200) {
        Toast('注册成功')
        window.clearInterval(this.InterValObj);// 停止计时器
        document.getElementById("sendCodeId").removeAttribute("disabled");//移除禁用状态改为可用
        setTimeout(() => {
          this.$router.push('/login')
        }, 500)
      }
    },
    SetRemainTime () {
      if (this.curCount == 0) {//超时重新获取验证码
        window.clearInterval(this.InterValObj);// 停止计时器
        document.getElementById("sendCodeId").removeAttribute("disabled");//移除禁用状态改为可用
        document.getElementById("sendCodeId").innerText = "重获验证码";
      } else {
        this.curCount--;
        document.getElementById("sendCodeId").innerText = this.curCount+"秒后获取";
      }
    }
  },
}
</script>
<style scoped lang="less"></style>
