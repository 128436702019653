<template>
  <div data-v-7e4f03de="" class="header" style="">
    <img data-v-7e4f03de=""
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABZUlEQVQ4T72UzUvDQBDF30QPgWq867VYyDEromiPWsWDf6sH8etowQ/c0FOl0quedU2hB5ORKQmk6W5aQc0xO/PbN29mh/DLH/0yD1XgMoAUAC94keQvAfgq4stAL4qiFwBvo9HoZDAYfNZBwzBc8X3/HMBGHMebADKJn1KolOoC2GPm7ng8Pu73+4kNmsMuiGhfYuM4btsUotlsBkEQXBHRjgtagd0bYzrD4dBYgfKzDjoPNlNycYsNKme+7xdlzihzKnRBpfNE1GbmB2PMYbnMss+1c1hWKkkCI6KO1vrDNQH/B6z4qCeGEylmdvq3aFOesiw7kGDP826IaKsOOlNyxbfHNE07vV7vXYBKqTUA1wC2XdApYKVMawMEysyX+fDXD3YURbf5c7ozxhy5RiO/WKC7dU/PU0o9A3hNkuR03nJotVqrjUbjjIjWtdahdTmI78XBgutr0qtyzp8v2B8Is4d+A4IoASR8GJyqAAAAAElFTkSuQmCC"
      class="type" style="display: none;"> 
      <img data-v-7e4f03de=""
      @click="goBack()"
      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAABR0lEQVQ4T63UsUrDUBQG4P80vQguVRcHZxFXqbg6iJtP4KzQRSp1SEHhlgy2DhbaQegj1DcwSHUVJys+gC8gQorEJjlyA5WWpLkxMVu44Tvn3v/kEv75oTTeiXW1zn5BdOXZm+57LagwwBgws0G+t9NpmO9JaCI4wQCsMXN/GDiHD1J6mcAsmCoU22FWLBbMg0XAvNgMWLFaGwLFexUAwP0XXx9AXDjhGVat9maAYABgVb0z+JWAxDSnMSZ67p7Xjn47PJXXK57BNgFbusGds/7Yuajtzmy5KttLfsG/I6JtZgwD9+tgJLzPNAVGgHcrpRMJ5bjZLC18F+0QBZ5cZ7zfa5mp0EnhyBzmRWMHO9y+EagzLf+107n/clY08XLIgmqvr4p5uSwWhc1E7IrxXs9MDkkLqvQUihJwU69/6MYoFahDptd/ANUGwhUwnh1lAAAAAElFTkSuQmCC"
      class="type" style="">
    <p data-v-7e4f03de="">{{title}}</p>
  </div>
</template>

<script>
export default {
  name: 'my-user',
  props: {
    title: {
      default: '标题'
    },
    pathName: {
      default: ''
    }
  },
  data() {
    return {

    };
  },
  computed: {},
  watch: {},
  created() {
  },
  mounted() { },
  methods: {
    goBack() {
      if(this.pathName) {
        this.$router.push({
          name: this.pathName
        })
      }else {
        this.$router.go(-1)
      }
    }
  },
}
</script>
<style scoped lang="less"></style>
