// 引入文件
import request from '@/plugin/request'

export const getCardList = (data) => {
    return request({
        url: 'api/getCardList',
        method: 'post',
        data
    })
}

export const openCard = (data) => {
    return request({
        url: 'api/openCard',
        method: 'post',
        data
    })
}

export const yue2card = (data) => {
    return request({
        url: 'api/yue2card',
        method: 'post',
        data
    })
}

export const card2yue = (data) => {
    return request({
        url: 'api/card2yue',
        method: 'post',
        data
    })
}

export const rechargeList = (data) => {
    return request({
        url: 'api/rechargeList',
        method: 'post',
        data
    })
}

export const withdrawList = (data) => {
    return request({
        url: 'api/withdrawList',
        method: 'post',
        data
    })
}

export const myCardList = (data) => {
    return request({
        url: 'api/myCardList',
        method: 'post',
        data
    })
}

export const getSystemInfo = (data) => {
    return request({
        url: 'api/getSystemInfo',
        method: 'post',
        data
    })
}

export const withdraw = (data) => {
    return request({
        url: 'api/withdraw',
        method: 'post',
        data
    })
}

export const recharge = (data) => {
    return request({
        url: 'api/recharge',
        method: 'post',
        data
    })
}

export const getAcList = (data) => {
    return request({
        url: 'api/acList',
        method: 'post',
        data
    })
}

export const userT2user = (data) => {
    return request({
        url: 'api/userT2user',
        method: 'post',
        data
    })
}

export const getTeamList = (data) => {
    return request({
        url: 'api/getTeamList',
        method: 'post',
        data
    })
}

export const getLevelList = (data) => {
    return request({
        url: 'api/getLevelList',
        method: 'post',
        data
    })
}

export const getAdsList = (data) => {
    return request({
        url: 'api/getAds',
        method: 'post',
        data
    })
}

export const getConsumeList = (data) => {
    return request({
        url: 'api/getConsumeList',
        method: 'post',
        data
    })
}

export const uploadFile = (data) => {
    return request({
        url: '/upload',
        method: 'post',
        data
    })
}





