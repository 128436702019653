
// 设置缓存
export const setCache = (name, value) => {
  if (!name) return;
  window.localStorage.setItem(name, JSON.stringify(value))
}
// 获取缓存
export const getCache = (name) => {
  const val = JSON.parse(window.localStorage.getItem(name))
  return val
}

// 获取缓存
export const clearCache = (name) => {
  window.localStorage.removeItem(name)
}