
import { getUserInfo } from '@/api'
import { setCache, getCache, clearCache } from '@/utils/cookies'

export default {
  state: {
    userInfo: {
      member: {}
    }
  },
  getters: {
    userInfo: state => state.userInfo,
    userMember: state => state.userInfo.member,
    userToken: state => state.userInfo.token,
    username: state => state.userInfo.account,
  },
  mutations: {
    setUserInfo(state, data) {
      state.userInfo = data
    },
  },
  actions: {
    async setUser({ commit }, data) {
      const res = await getUserInfo()
      if (res.code == 200) {
        let userInfo = {
          ...res.data,
          token: data?.token || ''
        }
        commit('setUserInfo', userInfo)
      }
      
    },
    clearUser({ commit }) {
      setCache('app-token', '')
      commit('setUserInfo', {})
      clearCache('app-token')
    }
  }
}