<template>
  <ul data-v-aea790d4="" class="footer">
    <li data-v-aea790d4="" @click="changePath('Card')">
      <img data-v-aea790d4=""
        src="@/assets/icons/download (17).png" v-if="pathName == 'Card'">
      <img data-v-aea790d4=""
        src="@/assets/icons/download (10).png" v-else>
      <p data-v-aea790d4="" class="">卡</p>
    </li>
    <li data-v-aea790d4="" @click="changePath('Account')">
      <img data-v-aea790d4="" v-if="pathName == 'Account'"
        src="@/assets/icons/download (46).png">
      <img data-v-aea790d4="" v-else
        src="@/assets/icons/download (36).png">
      <p data-v-aea790d4="" class="">账户</p>
    </li>
    <li data-v-aea790d4="" @click="changePath('Coin')">
      <img data-v-aea790d4="" v-if="pathName == 'Coin'"
        src="@/assets/icons/download (47).png">
      <img data-v-aea790d4="" v-else
        src="@/assets/icons/download (37).png">
      <p data-v-aea790d4="" class="">钱包</p>
    </li>
    <li data-v-aea790d4="" @click="changePath('User')">
      <img data-v-aea790d4="" v-if="pathName == 'User'"
        src="@/assets/icons/download (9).png">
      <img data-v-aea790d4="" v-else
        src="@/assets/icons/download (18).png">
      <p data-v-aea790d4="" class="active">我的</p>
      <div data-v-aea790d4="" class="bell" style="display: none;">
        <label data-v-aea790d4="">0</label>
      </div>
    </li>
</ul></template>

<script>
export default {
  name: 'foot-er',
  props: {

  },
  methods: {
    changePath(name) {
      this.$router.push({
        name: name
      })
    }
  },
  computed: {
    pathName() {
      return this.$route.name
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
