import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

const path = require('path')
const files = require.context('@/store/modules', false, /.js$/)
const modules = {}
files.keys().forEach(key => {
    const name = path.basename(key, '.js')
    const content = files(key).default || files(key)
    modules[name] = content
})

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: modules,
    plugins: [createPersistedState()]
})