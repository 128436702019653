<template>
  <div data-v-ebc3effa="" class="main1 pc_r">
    <!-- <img data-v-ebc3effa=""
      src="@/assets/icons/download (2).png"
      class="logo"> <img data-v-ebc3effa=""
      src="@/assets/icons/download (3).png"
      class="logon_r_bg"> -->
    <div data-v-ebc3effa="" class="info_outer">
      <p data-v-ebc3effa="" class="title">
        HELLO！<br data-v-ebc3effa=""> 欢迎回到Benforpay
      </p>
      <div data-v-ebc3effa="" class="login_choose">
        <div data-v-ebc3effa="" class="active">邮箱登录</div>
      </div>
      <div data-v-ebc3effa="" class="info">
        <input data-v-ebc3effa="" v-model="form.username" type="text"
          placeholder="请输入邮箱地址"></div>
      <div data-v-ebc3effa="" class="info">
        <input data-v-ebc3effa="" v-model="form.password" placeholder="请输入密码" :type="showPwd ? 'text' : 'password'"> 
        <img
          v-if="showPwd"
          @click="changeEye"
          data-v-ebc3effa=""
          src="@/assets/icons/download (5).png"
          class="show">
        <img
          v-else
          @click="changeEye"
          data-v-ebc3effa=""
          src="@/assets/icons/download (8).png"
          class="show">
      </div>
      <div data-v-ebc3effa="" class="info" style="display: flex;">
        <input data-v-ebc3effa="" v-model="form.verify_code" type="text"
          placeholder="请输入验证码">
        <img
          id="captchaImg"
          :src="codeInfo.base64_img"
          alt=""
          style="background: none; height: 40px; width: 100px;cursor:pointer"
          @click="getWithdrawData"
        />
      </div>
      <div data-v-ebc3effa="" class="login_switch">
        <label data-v-ebc3effa="" @click="goReg">创建账户</label> 
        <span
          data-v-ebc3effa="" @click="goForget">忘记密码</span></div> 
          <button data-v-ebc3effa=""  class="button0" @click="onSubmit">登录</button>
      <button data-v-ebc3effa="" disabled="disabled" class="button0" style="display: none;">登录</button>
    </div>
  </div>
</template>

<script>
import { login,getWithdraw } from "@/api"
import { mapActions } from 'vuex'
import { Button,Toast } from 'vant';
import { setCache, getCache } from '@/utils/cookies'
export default {
  name: 'my-login',
  components: {
    [Button.name]: Button,
  },
  props: {},
  data() {
    return {
      form:{
        username: '',
        password: '',
        verify_code: '',
        code_key: 'code_key',
      },
      codeInfo: {

      },
      showPwd: false
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getWithdrawData()
  },
  mounted() { },
  methods: {
    changeEye() {
      this.showPwd = !this.showPwd
    },
    async getWithdrawData() {
      const { code, data } = await getWithdraw()
      if (code == 200) {
        this.codeInfo =  data
      }
    },
    ...mapActions(['setUser']),
    
    goForget() {
      this.$router.push({
        name: 'ForgetPwd'
      })
    },
    goReg() {
      this.$router.push({
        path: '/register'
      })
    },
    async onSubmit() {
      // this.$router.push('/user')
      const { username, password, verify_code } = this.form
      if (!username.trim() || !password.trim()) {
        return Toast('请输入电子邮箱或邮箱密码');
      }
      if (!verify_code.trim()) {
        return Toast('请输入验证码')
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: false
      });
      const { code, data, message } = await login({
        account: username.trim(),
        password: password.trim(),
        code_key: this.codeInfo.code_key,
        verify_code: verify_code,
      }).finally(() => {
        Toast.clear();
      })
      if (code == 200) {
        setCache('app-token', data.token)
        this.setUser(data)
        Toast('登录成功')
        setTimeout(() => {
          this.$router.push('/user')
        }, 500)
      }
    },
  },
}
</script>
<style scoped lang="less">

</style>
